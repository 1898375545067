<template>
  <div class="mt-5">
    <b-container>
      <b-row no-gutters>
        <b-col lg="6" offset-lg="3" offset-sm="0">
          <b-card>
            <b-form @submit.prevent="login">
              <b-form-group>
                <b-form-input v-model="username" type="text" placeholder="Email" />
              </b-form-group>
              <b-form-group>
                <b-form-input
                  v-model="password"
                  type="password"
                  placeholder="Пароль"
                />
              </b-form-group>
              <b-alert v-model="error" dismissible variant="danger">
                {{ message }}
              </b-alert>
              <div class="text-center">
                <loading-button text="Войти" loading-text="Подождите" :is-busy="busyButton" />
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LoadingButton from '../components/LoadingButton'

export default {
  name: 'Login',
  components: {LoadingButton},
  metaInfo: {
    title: process.env.VUE_APP_TITLE + ' / Вход',
  },
  data() {
    return {
      busyButton: false,
      username: '',
      password: '',
      code: '',
      message: '',
      error: false,

    }
  },
  computed: {
    isEmail() {
      const re = /\S+@\S+\.\S+/
      return re.test(String(this.username).toLowerCase())
    },
  },
  methods: {
    login() {
      this.error = false
      this.busyButton = true

        this.axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/api/user/login', {
          username: this.username,
          password: this.password,
        })
          .then((response) => {
            this.busyButton = false
            if (response.data && response.data.error) {
              this.message = response.data.message === 'Invalid credentials' ? 'Неправильный логин или пароль' : response.data.message
              this.error = true
            } else {
              this.$store.commit('login', {
                access_token: response.data.access_token,
                password: this.password,
              })

                this.$router.push('/')

            }
          })
          .catch((error) => {
            console.log(error)
            this.busyButton = false
            this.message = 'Ошибка соединения'
            this.error = true
          })

    },
  },
}
</script>

<style scoped>

</style>
